import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

const StatusPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      bgcolor="#f9f9f9"
      padding="2rem"
    >
      <ErrorOutlineIcon style={{ fontSize: '5rem', color: '#f44336' }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Something Went Wrong
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
      We're sorry, there was a problem connecting to the server. Please try again.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoBack}>
        Go Back 
      </Button>
    </Box>
  );
};

export default StatusPage;
