import { configureStore } from "@reduxjs/toolkit";
import LoginSlice from "../Slices/Login/LoginSlice";
import SnackbarSlice from "../Slices/common/SnackbarSlice";
import BookEventSlice from "../Slices/BookEvent/BookEventSlice";

const store = configureStore({
    reducer: {
        Login: LoginSlice,
        SnackBarData: SnackbarSlice,
        BookEventData: BookEventSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export default store;