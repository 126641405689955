import { Backdrop, CircularProgress } from "@mui/material";


export default function Loader({ open = false, handleClose = () => { }, sx = {} }) {
    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }}
        open={open}
        onClick={handleClose}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
}