import { Add as AddIcon, ArrowBack as ArrowBackIcon, ArrowForwardIos as ArrowForwardIosIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { Autocomplete, Box, Button, Checkbox, Chip, Container, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../assets/PreviewEvent/CalendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../assets/PreviewEvent/ClockIcon.svg";
import { ReactComponent as TicketIcon } from "../../assets/PreviewEvent/TicketIcon.svg"
import { ReactComponent as LocationIcon } from "../../assets/TicketBooking/LocationIcon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/TicketBooking/SuccessIcon.svg"

import useCommonStyles from "../../Styles/CommonStyles";
import { useEffect, useState } from "react";
import MuiSelect from "../../Components/common/MuiSelect";
import { useDispatch, useSelector } from "react-redux";
import { createBooking, getClubNames, getDesignation, getEvent, getFoodTypes, getShirtType, setCreateBookingToNull, setGetClubNamesToNull, setGetDesignationToNull, setGetFoodTypesToNull, setGetShirtTypeToNull } from "../../Redux/Slices/BookEvent/BookEventSlice";
import moment from "moment";
import ValidationView from "../../Components/common/ValidationView";
import { focusError, hanlePriceOnChange, scrollIntoViewError } from "../../Utils";
import { openSnackbar } from "../../Redux/Slices/common/SnackbarSlice";

const initialState = {
    full_name: "",
    phone: '',
    email: '',
    club_name: '',
    designation: '',
    food_type_id: '',   //meal_preference
    registration_type_id: 1,
    is_annet_available: 0,
    total_amount: 0,

    //-->  registration_type_id == 2
    spouse_name: "",
    spouse_phone: "",
    spouse_meal_preference: "",

    //-->  registration_type_id == 3
    first_annet_name: "",
    second_annet_name: "",
    shirt_size_id: []

    //-->  registration_type_id == 4
    //  first_annet_name:""
}

export default function BuyTicket(params) {
    const navigate = useNavigate();
    const commonStyles = useCommonStyles();
    const dispatch = useDispatch();
    const { id: Slug } = useParams();

    const [registrationTypes, setRegistrationTypes] = useState([
        { label: 'Single Rotarian Registration', id: 1 },
        { label: 'Couple Registration', id: 2 },
        { label: 'Maharaja Circle - RAJADIRAJA', id: 3 },
        { label: 'Maharaja Circle - YUVARAJA', id: 4 },
        { label: 'Guest Registration', id: 5 },
    ]);
    const [registrationType, setRegistrationType] = useState("");

    const [inputData, setInputData] = useState(initialState);
    const [inputError, setInputError] = useState({});

    const [showSuccess, setShowSuccess] = useState(false);

    const [annetsData, setAnnetsData] = useState([]);

    const [clubNameData, setClubNameData] = useState([]);
    const [designationData, setDesignationData] = useState([]);
    const [foodTypeData, setFoodTypeData] = useState([]);



    const getEventData = useSelector(state => state.BookEventData?.getEvent)
    const getClubNamesData = useSelector(state => state.BookEventData?.getClubNames)
    const getDesignationData = useSelector(state => state.BookEventData?.getDesignation);
    const getFoodTypesData = useSelector(state => state.BookEventData?.getFoodTypes);
    const getShirtTypeData = useSelector(state => state.BookEventData?.getShirtType);
    const createBookingData = useSelector(state => state.BookEventData?.createBooking);
    const createBookingLoading = useSelector(state => state.BookEventData?.createBookingLoading);




    const eventData = getEventData?.data?.event ?? {}
    let EventAddress = eventData?.address && typeof eventData?.address === "string" ?
        eventData?.address?.split(",") : [];



    useEffect(() => {
        dispatch(getClubNames());
        dispatch(getDesignation())
        dispatch(getFoodTypes())
        dispatch(getShirtType())

        if (!getEventData?.data?.event && Slug) {
            dispatch(getEvent(Slug))
        }

        return () => {
            dispatch(setGetClubNamesToNull())
            dispatch(setGetDesignationToNull());
            dispatch(setGetFoodTypesToNull())
            dispatch(setGetShirtTypeToNull())
        }
    }, [])

    useEffect(() => {
        if (getEventData?.data?.event) {
            // console.log(getEventData?.data?.event, 'getEventData?.data?.event');

            if (getEventData?.data?.event?.food_availability &&
                Array.isArray(getEventData?.data?.event?.food_availability)
            ) {
                let newData = [];
                newData = getEventData?.data?.event?.food_availability?.map((item) => {
                    return item.food_type
                })
                setFoodTypeData(newData)
            }

            if (getEventData?.data?.event?.annets &&
                Array.isArray(getEventData?.data?.event?.annets)
            ) {
                let newData = [];

                for (const item of getEventData?.data?.event?.annets) {
                    newData.push({
                        annets_type_id: item.id,
                        price: Number(item?.price),
                        annet_type: item.annet_type,
                        no_of_annets: "",
                    })
                }
                setAnnetsData(newData)
            }

        }

    }, [getEventData])


    useEffect(() => {
        let newData = [];
        if (getClubNamesData?.data?.club_name && typeof getClubNamesData?.data?.club_name === "object") {
            for (const key in getClubNamesData?.data?.club_name) {
                newData.push({
                    value: key,
                    club_name: getClubNamesData?.data?.club_name[key]
                })
            }
        }
        setClubNameData(newData);

    }, [getClubNamesData])

    useEffect(() => {
        let newData = [];
        if (getDesignationData?.data?.designation && typeof getDesignationData?.data?.designation === "object") {
            for (const key in getDesignationData?.data?.designation) {
                newData.push({
                    value: key,
                    designation: getDesignationData?.data?.designation[key]
                })
            }
        }
        setDesignationData(newData);

    }, [getDesignationData])


    useEffect(() => {


        if (createBookingData?.success === true) {
            dispatch(openSnackbar({ message: createBookingData?.toast, severity: 'success' }))
            dispatch(setCreateBookingToNull())
            setShowSuccess(true)
            setInputData(initialState)
            setInputError({})
        }
        else if (createBookingData?.success === false &&
            createBookingData?.data?.validation_error &&
            typeof createBookingData?.data?.validation_error === "object"
        ) {

            setInputError(prev => ({ ...prev, ...createBookingData?.data?.validation_error }))
            dispatch(setCreateBookingToNull())

        } else if (createBookingData?.success === false
            && createBookingData?.error
            && typeof createBookingData?.error === "string") {

            dispatch(openSnackbar({ message: createBookingData?.error, severity: 'error' }))
            dispatch(setCreateBookingToNull())
        }


    }, [createBookingData])


    const calculate = () => {
        let TotalAmount = 0;
        TotalAmount = annetsData.reduce((acc, item) => acc + (item.price * item.no_of_annets), 0)
        setInputData(prev => ({ ...prev, total_amount: Number(TotalAmount) + Number(registrationType?.price ?? 0) }))
    }

    useEffect(() => {
        calculate()
    }, [annetsData, registrationType])


    const handleRegistrationType = ({ id, registration_type_id, price }) => {
        setRegistrationType({ id: id, registration_type_id: registration_type_id, price: price });
        setInputData(prev => ({
            ...prev,
            registration_type_id: registration_type_id,
            spouse_name: "",
            spouse_phone: "",
            spouse_meal_preference: "",
            first_annet_name: "",
            second_annet_name: "",
            shirt_size_id: []
        }))
        setInputError(prev => ({
            ...prev,
            registration_type_id: false,
            spouse_name: "",
            spouse_phone: "",
            spouse_meal_preference: "",
            first_annet_name: "",
            second_annet_name: "",
            shirt_size_id: ""
        }))
    }


    const handleCheckBox = (e) => {
        const { name, checked, value } = e.target;

        setInputData(prev => {
            const index = prev[name]?.indexOf(Number(value))

            if (index > -1) {
                prev[name]?.splice(index, 1)
            } else {
                prev[name]?.push(Number(value))
            }
            return { ...prev }
        })
        setInputError(prev => ({ ...prev, [name]: false }))
    }


    const findDuration = (startTime, endTime) => {

        if (startTime && endTime) {
            let start = moment(startTime, 'HH:mm');
            let end = moment(endTime, 'HH:mm');



            // Calculate the duration
            let duration = moment.duration(end.diff(start));

            // Get the duration in hours and minutes
            let hours = Math.floor(duration.asHours());
            let minutes = duration.minutes();

            return `${hours} Hours ${Number(minutes) > 0 ? `${minutes} Minutes` : ''}`
        } else return "-"
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({ ...prev, [name]: value }))
        setInputError(prev => ({ ...prev, [name]: false }))
    }

    const handleAddRemove = (name, type = 0, index) => {
        setAnnetsData(prev => {
            let preValue = Number(prev[index][name])

            if (type == 1) { preValue++ }
            else { preValue-- }

            prev[index][name] = preValue
            return [...prev]
        })

        // setInputData(prev => {
        //     let preValue = Number(prev[name])
        //     if (type == 1) { preValue++ }
        //     else { preValue-- }
        //     return { ...prev, [name]: preValue }
        // })
    }

    const handleAnnetsChange = (e, index) => {
        const { name, value } = e.target;
        setAnnetsData(prev => {
            prev[index][name] = value;
            return [...prev]
        })
    }

    const validate = () => {
        let valid = true;

        if (!inputData?.full_name) {
            setInputError(prev => ({ ...prev, full_name: "The full name field is required" }));
            focusError("full_name")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, full_name: "" }))
        }

        if (!inputData?.phone) {
            setInputError(prev => ({ ...prev, phone: "The phone field is required" }));
            focusError("phone")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, phone: "" }))
        }

        if (!inputData?.email) {
            setInputError(prev => ({ ...prev, email: "The email field is required" }));
            focusError("email")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, email: "" }))
        }

        if (!inputData?.club_name) {
            setInputError(prev => ({ ...prev, club_name: "The club name field is required" }));
            focusError("club_name")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, club_name: "" }))
        }

        if (!inputData?.designation) {
            setInputError(prev => ({ ...prev, designation: "The designation field is required" }));
            focusError("designation")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, designation: "" }))
        }

        if (!inputData?.food_type_id) {
            setInputError(prev => ({ ...prev, food_type_id: "The Meal Preference field is required" }));
            focusError("food_type_id")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, food_type_id: "" }))
        }

        if (!inputData?.food_type_id) {
            setInputError(prev => ({ ...prev, food_type_id: "The Meal Preference field is required" }));
            focusError("food_type_id")
            valid = false
        } else {
            setInputError(prev => ({ ...prev, food_type_id: "" }))
        }


        if (inputData?.registration_type_id) {

            setInputError(prev => ({ ...prev, registration_type_id: "" }));

            if (inputData?.registration_type_id === 2) {

                if (!inputData?.spouse_name) {
                    setInputError(prev => ({ ...prev, spouse_name: "The spouse name is required" }));
                    focusError("spouse_name_2")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_name: "" }))
                }

                if (!inputData?.spouse_phone) {
                    setInputError(prev => ({ ...prev, spouse_phone: "The spouse name is required" }));
                    focusError("spouse_phone_2")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_phone: "" }))
                }

                if (!inputData?.spouse_meal_preference) {
                    setInputError(prev => ({ ...prev, spouse_meal_preference: "The spouse name is required" }));
                    scrollIntoViewError("spouse_meal_preference_2")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_meal_preference: "" }))
                }


            } else if (inputData?.registration_type_id === 3) {

                if (!inputData?.shirt_size_id || !inputData?.shirt_size_id?.length) {
                    setInputError(prev => ({ ...prev, shirt_size_id: "The shirt size is required" }));
                    focusError("shirt_size_id")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, shirt_size_id: "" }))
                }

                if (!inputData?.spouse_name) {
                    setInputError(prev => ({ ...prev, spouse_name: "The spouse name is required" }));
                    focusError("spouse_name_3")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_name: "" }))
                }

                if (!inputData?.spouse_phone) {
                    setInputError(prev => ({ ...prev, spouse_phone: "The spouse mobile is required" }));
                    focusError("spouse_phone_3")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_phone: "" }))
                }

                if (!inputData?.spouse_meal_preference) {
                    setInputError(prev => ({ ...prev, spouse_meal_preference: "The meal preference is required" }));
                    scrollIntoViewError("spouse_meal_preference_3")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_meal_preference: "" }))
                }

                if (!inputData?.first_annet_name) {
                    setInputError(prev => ({ ...prev, first_annet_name: "The first annet name is required" }));
                    scrollIntoViewError("first_annet_name_3")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, first_annet_name: "" }))
                }

                if (!inputData?.second_annet_name) {
                    setInputError(prev => ({ ...prev, second_annet_name: "The second annet name is required" }));
                    scrollIntoViewError("second_annet_name_3")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, second_annet_name: "" }))
                }




            } else if (inputData?.registration_type_id === 4) {

                if (!inputData?.spouse_name) {
                    setInputError(prev => ({ ...prev, spouse_name: "The spouse name is required" }));
                    focusError("spouse_name_4")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_name: "" }))
                }

                if (!inputData?.spouse_phone) {
                    setInputError(prev => ({ ...prev, spouse_phone: "The spouse mobile is required" }));
                    focusError("spouse_phone_4")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_phone: "" }))
                }

                if (!inputData?.spouse_meal_preference) {
                    setInputError(prev => ({ ...prev, spouse_meal_preference: "The meal preference is required" }));
                    scrollIntoViewError("spouse_meal_preference_4")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, spouse_meal_preference: "" }))
                }

                if (!inputData?.first_annet_name) {
                    setInputError(prev => ({ ...prev, first_annet_name: "The first annet name is required" }));
                    focusError("first_annet_name_4")
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, first_annet_name: "" }))
                }

            }
        } else if (!inputData?.registration_type_id) {

            setInputError(prev => ({ ...prev, registration_type_id: "The Registration type is required" }));
            focusError("registration_type_id")
            valid = false

        }

        return valid

    }



    const handleProceed = () => {
        const isValid = validate();
        if (!isValid) return;

        const formData = new FormData();

        formData.append("full_name", inputData.full_name)
        formData.append("phone", inputData.phone)
        formData.append("email", inputData.email)
        formData.append("club_name", inputData.club_name)
        formData.append("designation", inputData.designation)
        formData.append("food_type_id", inputData.food_type_id)
        formData.append("registration_type_id", inputData.registration_type_id)
        formData.append("is_annet_available", inputData.is_annet_available)

        if (annetsData && annetsData?.length) {
            const newData = annetsData.map((item) => ({ annets_type_id: item?.annets_type_id, no_of_annets: Number(item?.no_of_annets) }))
            formData.append("annets", JSON.stringify(newData))
        }

        if (inputData?.registration_type_id === 2) {

            formData.append("spouse_name", inputData.spouse_name)
            formData.append("spouse_phone", inputData.spouse_phone)
            formData.append("spouse_meal_preference", inputData.spouse_meal_preference)

        } else if (inputData?.registration_type_id === 3) {

            for (const shirt_id of inputData?.shirt_size_id) {
                // formData.append("shirt_size_id[]", shirt_id)
                formData.append("shirt_size_id", shirt_id)
            }
            formData.append("spouse_name", inputData.spouse_name)
            formData.append("spouse_phone", inputData.spouse_phone)
            formData.append("spouse_meal_preference", inputData.spouse_meal_preference)
            formData.append("first_annet_name", inputData.first_annet_name)
            formData.append("second_annet_name", inputData.second_annet_name)


        } else if (inputData?.registration_type_id === 4) {

            formData.append("spouse_name", inputData.spouse_name)
            formData.append("spouse_phone", inputData.spouse_phone)
            formData.append("spouse_meal_preference", inputData.spouse_meal_preference)
            formData.append("first_annet_name", inputData.first_annet_name)

        }

        dispatch(createBooking({ payload: formData, id: eventData.id }))

    }



    return <>
        <Container >
            {showSuccess ? <>
                <Box mt={1}>
                    <Button sx={{ color: '#202224' }} startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            // setShowSuccess(false)
                            navigate(`/${Slug}`)
                        }}
                    >
                        Back
                    </Button>
                </Box>
                <Box sx={{ backgroundColor: '#FFFFFF', width: '100%', borderRadius: "20px" }}>
                    <Box sx={{ display: 'flex', borderBottom: "1px solid #A9A9A980", flexDirection: 'column', gap: '5px', alignItems: 'center', justifyContent: 'center', padding: '15px 20px', borderRadius: "20px 20px 0px 0px" }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Success</Typography>
                        <Typography sx={{ fontSize: '14px', color: "#6A6A6A" }}>Ticket details for the event are sent to email</Typography>
                    </Box>

                    <Box sx={{ height: '400px', display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'center', justifyContent: 'center', }}>
                        <SuccessIcon />
                        <Typography
                            mt={1}
                            sx={{ color: "#242565" }}
                        >Ticket details with entry QR will be sent to email once confirmed by event host</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px 20px', boxShadow: '0px -4px 10px 0px #1D1D1D26', borderRadius: "0px 0px 20px 20px" }}>
                        <Button onClick={() => navigate(`/${Slug}`)} variant="contained" >Go to home</Button>
                    </Box>
                </Box>

            </> :
                <>
                    <Box mt={1}>
                        <Button sx={{ color: '#202224' }} startIcon={<ArrowBackIcon />}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </Box>

                    <Box sx={{ backgroundColor: '#FFFFFF', width: '100%', borderRadius: "20px" }}>
                        <Grid container sx={{ borderBottom: '1px solid #A9A9A980' }} >
                            <Grid item
                                sm={6}
                            >
                                <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{eventData?.title}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <CalendarIcon style={{ padding: "5px 0px" }} /> &nbsp;
                                        <Typography className={commonStyles.InputLabel}>{moment(eventData?.duration_start_date).format("dddd, D MMMM YYYY")}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ClockIcon style={{ padding: "5px 0px" }} /> &nbsp;
                                        <Typography className={commonStyles.InputLabel}>{Boolean(eventData?.duration_from_time) && moment(eventData?.duration_from_time, 'HH:mm').format('h:mm A')} -  {Boolean(eventData?.duration_to_time) && moment(eventData?.duration_to_time, 'HH:mm').format('h:mm A')}</Typography>&nbsp;
                                        <Typography className={commonStyles.InputLabel}>{findDuration(eventData?.duration_from_time, eventData?.duration_to_time)}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} >
                                <Box sx={{ padding: '10px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <LocationIcon />
                                        <Box>
                                            {EventAddress?.length ? EventAddress?.map((item, i) => (
                                                <Typography
                                                    className={commonStyles.InputLabel}>
                                                    {item} {EventAddress?.length === i + 1 ? "." : ","}
                                                </Typography>
                                            )) : "-"}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ padding: '10px 25px' }}>
                            <Box mt={1}
                                className={commonStyles.customBox}
                            >
                                <Box sx={{ borderBottom: '1px solid #A9A9A980', padding: '15px 10px' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Details</Typography>
                                </Box>
                                <Grid container spacing={2} p={"15px"}  >
                                    <>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Full Name</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Name"
                                                    name="full_name"
                                                    onChange={handleChange}
                                                    value={inputData?.full_name}
                                                    error={Boolean(inputError?.full_name)}
                                                    helperText={inputError.full_name}
                                                    id="full_name"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Phone</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Phone"
                                                    name="phone"
                                                    onChange={handleChange}
                                                    value={inputData.phone}
                                                    error={Boolean(inputError?.phone)}
                                                    helperText={inputError.phone}
                                                    id="phone"
                                                />
                                            </Box>
                                        </Grid>
                                    </>

                                    <>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >E-mail</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="E-mail"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={inputData.email}
                                                    error={Boolean(inputError?.email)}
                                                    helperText={inputError.email}
                                                    id="email"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Club name</Typography>
                                                <MuiSelect
                                                    className={commonStyles.commonAutoComplete}
                                                    classes={{
                                                        noOptions: commonStyles.dropDownLabel,
                                                        loading: commonStyles.dropDownLabel,
                                                        listbox: commonStyles.dropDownLabel
                                                    }}
                                                    onChange={handleChange}
                                                    options={clubNameData}
                                                    name="club_name"
                                                    value={inputData?.club_name ?? ""}
                                                    valueKey="club_name"
                                                    labelKey='club_name'
                                                    placeholder="Select Club Name"
                                                    id="club_name"
                                                />
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.club_name}</ValidationView>
                                            </Box>
                                        </Grid>
                                    </>

                                    <>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Your Designation</Typography>
                                                <MuiSelect
                                                    className={commonStyles.commonAutoComplete}
                                                    classes={{
                                                        noOptions: commonStyles.dropDownLabel,
                                                        loading: commonStyles.dropDownLabel,
                                                        listbox: commonStyles.dropDownLabel
                                                    }}
                                                    onChange={handleChange}
                                                    options={designationData}
                                                    name="designation"
                                                    value={inputData?.designation ?? ""}
                                                    valueKey="designation"
                                                    labelKey='designation'
                                                    placeholder="Select Designation Name"
                                                    id="designation"
                                                />
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.designation}</ValidationView>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Meal Preference</Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="food_type_id"
                                                        defaultValue="top"
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        onChange={handleChange}
                                                        value={inputData?.food_type_id ? inputData?.food_type_id?.toString() : ''}
                                                    >
                                                        {foodTypeData && Array.isArray(foodTypeData) ?
                                                            foodTypeData?.map((item) => {
                                                                return <FormControlLabel
                                                                    value={item?.id}
                                                                    control={<Radio />}
                                                                    label={item?.name}
                                                                    labelPlacement="end"
                                                                />
                                                            }) : null
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.food_type_id}</ValidationView>
                                            </Box>
                                        </Grid>
                                    </>
                                </Grid>

                            </Box>

                            <Box mt={3}
                                className={commonStyles.customBox}
                            >
                                <Box sx={{ borderBottom: '1px solid #A9A9A980', padding: '15px 10px' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Registration</Typography>
                                </Box>

                                <Box padding={"15px"}>
                                    <Typography className={commonStyles.InputLabel} >Registration Type</Typography>
                                    <Stack
                                        direction={"row"}
                                        gap={"10px"}
                                        flexWrap={"wrap"}
                                        padding={"10px"}
                                    >
                                        {Array.isArray(eventData?.ticket?.registration) && eventData?.ticket?.registration?.map((item, index) => {
                                            return <Chip sx={{ cursor: 'pointer' }} onClick={() => handleRegistrationType({ id: item.id, registration_type_id: item.registration_type_id, price: item.price })} color={item.id === registrationType?.id ? "primary" : "default"} variant="outlined" label={item.ticket_name}></Chip>
                                        })}
                                    </Stack>
                                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.registration_type_id}</ValidationView>
                                </Box>

                                {registrationType?.registration_type_id === 2 && <Box padding={"15px"}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Spouse name</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Name"
                                                    name="spouse_name"
                                                    value={inputData.spouse_name}
                                                    onChange={handleChange}
                                                    error={Boolean(inputError.spouse_name)}
                                                    helperText={inputError.spouse_name}
                                                    id="spouse_name_2"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Spouse mobile</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Mobile"
                                                    name="spouse_phone"
                                                    value={inputData.spouse_phone}
                                                    onChange={(e) => hanlePriceOnChange(e, handleChange)}
                                                    error={Boolean(inputError.spouse_phone)}
                                                    helperText={inputError.spouse_phone}
                                                    id="spouse_phone_2"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Spouse Meal Preference</Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        defaultValue="top"
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        name="spouse_meal_preference"
                                                        onChange={handleChange}
                                                        value={inputData?.spouse_meal_preference ? inputData?.spouse_meal_preference?.toString() : ''}
                                                        id="spouse_meal_preference_2"
                                                    >
                                                        {foodTypeData && Array.isArray(foodTypeData) ?
                                                            foodTypeData?.map((item) => {
                                                                return <FormControlLabel
                                                                    value={item?.name}
                                                                    control={<Radio />}
                                                                    label={item?.name}
                                                                    labelPlacement="end"
                                                                />
                                                            }) : null
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.spouse_meal_preference}</ValidationView>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>}

                                {registrationType?.registration_type_id === 3 && <Box padding={"15px"}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Shirt Size</Typography>
                                                <FormGroup>
                                                    {Array.isArray(getShirtTypeData?.data?.shirt_size) ? getShirtTypeData?.data?.shirt_size?.map((item) => {
                                                        return < FormControlLabel name="shirt_size_id" value={item.id} onChange={handleCheckBox} control={<Checkbox checked={inputData?.shirt_size_id?.includes(item?.id)} />} label={item?.name} />
                                                    }) : null}
                                                </FormGroup>
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.shirt_size_id}</ValidationView>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box mt={2}>
                                                <Typography className={commonStyles.InputLabel} >Spouse name</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="name"
                                                    name="spouse_name"
                                                    value={inputData.spouse_name}
                                                    onChange={handleChange}
                                                    error={Boolean(inputError.spouse_name)}
                                                    helperText={inputError.spouse_name}
                                                    id="spouse_name_3"
                                                />
                                            </Box>

                                            <Box mt={2}>
                                                <Typography className={commonStyles.InputLabel} >Spouse mobile</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Mobile"
                                                    name="spouse_phone"
                                                    value={inputData.spouse_phone}
                                                    onChange={(e) => hanlePriceOnChange(e, handleChange)}
                                                    error={Boolean(inputError.spouse_phone)}
                                                    helperText={inputError.spouse_phone}
                                                    id="spouse_phone_3"
                                                />
                                            </Box>

                                            <Box mt={2} >
                                                <Typography className={commonStyles.InputLabel} >Spouse Meal Preference</Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        defaultValue="top"
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        name="spouse_meal_preference"
                                                        onChange={handleChange}
                                                        value={inputData?.spouse_meal_preference ? inputData?.spouse_meal_preference?.toString() : ''}
                                                    >
                                                        {foodTypeData && Array.isArray(foodTypeData) ?
                                                            foodTypeData?.map((item) => {
                                                                return <FormControlLabel
                                                                    value={item?.name}
                                                                    control={<Radio />}
                                                                    label={item?.name}
                                                                    labelPlacement="end"
                                                                />
                                                            }) : null
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.spouse_meal_preference}</ValidationView>
                                            </Box>

                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3} spacing={2}>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >1st Annet Name (below 12 years)</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="name"
                                                    name="first_annet_name"
                                                    onChange={handleChange}
                                                    value={inputData?.first_annet_name}
                                                    error={Boolean(inputError?.first_annet_name)}
                                                    helperText={inputError?.first_annet_name}
                                                    id="first_annet_name_3"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >2nd Annet Name (below 12 years)</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="name"
                                                    name="second_annet_name"
                                                    onChange={handleChange}
                                                    value={inputData?.second_annet_name}
                                                    error={Boolean(inputError?.second_annet_name)}
                                                    helperText={inputError?.second_annet_name}
                                                    id="second_annet_name_3"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}></Grid>
                                    </Grid>
                                </Box>}

                                {registrationType?.registration_type_id === 4 && <Box padding={"15px"}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Spouse name</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Name"
                                                    name="spouse_name"
                                                    value={inputData.spouse_name}
                                                    onChange={handleChange}
                                                    error={Boolean(inputError.spouse_name)}
                                                    helperText={inputError.spouse_name}
                                                    id="spouse_name_4"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Spouse mobile</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Mobile"
                                                    name="spouse_phone"
                                                    value={inputData.spouse_phone}
                                                    onChange={(e) => hanlePriceOnChange(e, handleChange)}
                                                    error={Boolean(inputError.spouse_phone)}
                                                    helperText={inputError.spouse_phone}
                                                    id="spouse_phone_4"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Spouse Meal Preference</Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        defaultValue="top"
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        name="spouse_meal_preference"
                                                        onChange={handleChange}
                                                        value={inputData?.spouse_meal_preference ? inputData?.spouse_meal_preference?.toString() : ''}
                                                    >
                                                        {foodTypeData && Array.isArray(foodTypeData) ?
                                                            foodTypeData?.map((item) => {
                                                                return <FormControlLabel
                                                                    value={item?.name}
                                                                    control={<Radio />}
                                                                    label={item?.name}
                                                                    labelPlacement="end"
                                                                />
                                                            }) : null
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.spouse_meal_preference}</ValidationView>

                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >1st Annet Name (below 12 years)</Typography>
                                                <TextField
                                                    className={commonStyles.customInput}
                                                    fullWidth
                                                    placeholder="Name"
                                                    name="first_annet_name"
                                                    onChange={handleChange}
                                                    value={inputData?.first_annet_name}
                                                    error={Boolean(inputError?.first_annet_name)}
                                                    helperText={inputError?.first_annet_name}
                                                    id="first_annet_name_4"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>}
                            </Box>

                            <Box mt={3}
                                className={commonStyles.customBox}
                            >
                                <Box sx={{ borderBottom: '1px solid #A9A9A980', padding: '15px 10px' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Annets</Typography>
                                </Box>
                                <Grid container spacing={2} p={"15px"}  >
                                    <>
                                        <Grid item sm={4}>
                                            <Box >
                                                <Typography className={commonStyles.InputLabel} >Will your annet be attending event with you?</Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="is_annet_available"
                                                        defaultValue="top"
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        onChange={(e) => handleChange(e)}
                                                        value={inputData.is_annet_available}
                                                    >
                                                        <FormControlLabel
                                                            value={1}
                                                            control={<Radio />}
                                                            label="Yes"
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={0}
                                                            control={<Radio />}
                                                            label="No"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                <ValidationView sx={{ fontSize: '12px' }}>{inputError?.is_annet_available}</ValidationView>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={8}>
                                            <Grid container columnSpacing={2}>
                                                {annetsData?.map((item, index) => {
                                                    return <Grid item sm={6}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            border: '1px solid #A9A9A980',
                                                            padding: '10px',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Typography
                                                                sx={{ fontSize: '14px', fontWeight: 600 }}
                                                            >
                                                                {item?.annet_type}
                                                            </Typography>

                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                <TicketIcon /> &nbsp;
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '14px',
                                                                        fontWeight: '700',
                                                                    }}
                                                                >

                                                                    ₹ {item?.price}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box mt={1} sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            padding: '10px',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                            borderRadius: '10px'
                                                        }}>
                                                            <Typography className={commonStyles.InputLabel}
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                Number of Annets
                                                            </Typography>
                                                            <Box sx={{
                                                                border: '1px solid #828282B2',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                borderRadius: '10px'
                                                            }}>
                                                                <IconButton onClick={() => handleAddRemove("no_of_annets", 0, index)}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                                <TextField sx={{ width: '65px' }} className={commonStyles.amountInput}
                                                                    name="no_of_annets"
                                                                    onChange={(e) => hanlePriceOnChange(e, (event) => handleAnnetsChange(event, index))}
                                                                    value={item.no_of_annets}
                                                                />

                                                                <IconButton onClick={() => handleAddRemove("no_of_annets", 1, index)}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                })}

                                               
                                            </Grid>

                                        </Grid>
                                    </>
                                </Grid>

                            </Box>

                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '15px 20px', boxShadow: '0px -4px 10px 0px #1D1D1D26', borderRadius: "0px 0px 20px 20px" }}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={"10px"}
                            >
                                <Typography sx={{ fontWeight: "600", color: '#009C2C' }}>₹ {Number(inputData?.total_amount)}</Typography>
                                <Button disabled={createBookingLoading} onClick={() => handleProceed()} variant="contained" endIcon={<ArrowForwardIosIcon style={{ fontSize: '10px' }} />}>Proceed</Button>
                            </Stack>
                        </Box>
                    </Box>
                </>}
        </Container>
    </>
}