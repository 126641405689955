import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Container, Grid, Stack, Typography } from "@mui/material";
import PreviewCoverImage from "../../assets/CreateEvent/PreviewCoverImage.png"
import { Circle as CircleIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import useCommonStyles from "../../Styles/CommonStyles";
import { ReactComponent as TicketIcon } from "../../assets/PreviewEvent/TicketIcon.svg"
import { ReactComponent as BuyTicketIcon } from "../../assets/PreviewEvent/BuyTicketIcon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/PreviewEvent/CalendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../assets/PreviewEvent/ClockIcon.svg";
import { ReactComponent as MapPinIcon } from "../../assets/PreviewEvent/MapPinIcon.svg";
import PreviewMap from "./PreviewMap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvent } from "../../Redux/Slices/BookEvent/BookEventSlice";
import Loader from "../../Components/common/Loader";
import moment from "moment";
import CustomizedAccordions from "../../Components/common/CustomizedAccordions";
import { isJSONString } from "../../Utils";


export default function EventPreview(params) {
    const commonStyles = useCommonStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [fromAmount, setFromAmount] = useState(0)

    const getEventData = useSelector(state => state.BookEventData?.getEvent)
    const getEventLoading = useSelector(state => state.BookEventData?.getEventLoading)

    const eventDatas = getEventData?.data?.event ? getEventData?.data?.event : {}

    useEffect(() => {
        if (id) {
            dispatch(getEvent(id))
        }

        return () => {
            // dispatch
        }
    }, [])

    useEffect(() => {

        if (getEventData?.data?.event) {
            if (Array.isArray(getEventData?.data?.event?.ticket?.registration)) {

                const prices = getEventData?.data?.event?.ticket?.registration?.map((item) => {
                    return item.price
                })
                const leastPrice = Math.min(...prices);
                setFromAmount(leastPrice)
            }


        }

    }, [getEventData])

    const openGoogleMaps = (lat, lng) => {
        const url = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(url, '_blank');
    }

    const findDuration = (startTime, endTime) => {

        if (startTime && endTime) {
            let start = moment(startTime, 'HH:mm');
            let end = moment(endTime, 'HH:mm');



            // Calculate the duration
            let duration = moment.duration(end.diff(start));

            // Get the duration in hours and minutes
            let hours = Math.floor(duration.asHours());
            let minutes = duration.minutes();

            return `${hours} hours ${Number(minutes) > 0 ? `${minutes} minutes` : ''}`
        } else return "-"
    }




    return <Container sx={{}}>
        <Loader open={getEventLoading} />

        <Box sx={{
            // border: '1px solid #0B6BC2',
            // borderRadius: '10px',
            // padding: '20px'

        }}>
            <Box
                sx={{
                    background: {
                        xs: "unset",
                        sm: "linear-gradient(180deg, #611180 0%, rgba(184, 0, 255, 0) 100%)",
                        md: "linear-gradient(180deg, #611180 0%, rgba(184, 0, 255, 0) 100%)",
                        xl: "linear-gradient(180deg, #611180 0%, rgba(184, 0, 255, 0) 100%)"
                    },
                    height: { xl: '550px', md: '550px', sm: '550px', xs: 'unset' },
                    position: 'relative'
                }
                }
            >

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    position: { xl: "absolute", md: 'absolute', sm: 'absolute', xs: 'unset' },
                    top: 0,
                    transform: { xl: "translateY(30%)", md: 'translateY(30%)', sm: 'translateY(30%)', xs: 'unset' }
                }}>
                    <Box id="imagecard" sx={{
                        height: '370px',
                        width: '80%',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '',
                        boxShadow: "0px 10px 50px 0px #3D37F140",
                        borderRadius: '20px',
                        padding: '10px'
                    }}>
                        <img src={process.env.REACT_APP_BASE_URL + eventDatas?.cover_image} style={{

                            height: '100%',
                            width: "100%",
                            borderRadius: '20px'
                            // objectFit: 'contain'
                        }} />
                    </Box>
                </Box>
            </Box>


            <Box sx={{ padding: '10px', mt: 1 }}>
                <Box>
                    <Grid container
                        direction="row"
                        justifyContent="space-around"
                    // alignItems="center"
                    >
                        <Grid item sm={10} xs={12}>
                            <Box>
                                <Typography
                                    sx={{ fontWeight: '600', fontSize: '18px', color: '#2D2C3C' }}
                                >
                                    {eventDatas?.title}
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}
                                    >
                                        {eventDatas?.rotarian?.rotarian_district}
                                    </Typography> &nbsp;
                                    <CircleIcon sx={{ fontSize: '10px' }} />
                                    &nbsp;
                                    <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}>
                                        {eventDatas?.rotarian?.rotarian_id}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={2} xs={12}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Box sx={{ width: '170px' }} >
                                <Box sx={{
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderBottom: '1px solid #0B6BC2'
                                }}>
                                    <Typography sx={{}} className={commonStyles.InputLabel}>From</Typography>
                                    <TicketIcon />
                                    <Typography sx={{ fontSize: "14px", fontWeight: '700' }}>
                                        ₹ {fromAmount}
                                    </Typography>
                                </Box>

                                <Button fullWidth variant="contained" sx={{ marginTop: '10px', borderRadius: '50px' }}
                                    startIcon={<BuyTicketIcon style={{ color: '#fff' }} />}
                                    onClick={() => navigate(`/buy_ticket/status`)}
                                >
                                    Register
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>Date & Time</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <CalendarIcon style={{ padding: "5px 0px" }} /> &nbsp;
                        <Typography className={commonStyles.InputLabel}>{moment(eventDatas?.duration_start_date).format("dddd, D MMMM YYYY")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ClockIcon style={{ padding: "5px 0px" }} /> &nbsp;
                        <Typography className={commonStyles.InputLabel}>{Boolean(eventDatas?.duration_from_time) && moment(eventDatas?.duration_from_time, 'HH:mm').format('h:mm A')} -  {Boolean(eventDatas?.duration_to_time) && moment(eventDatas?.duration_to_time, 'HH:mm').format('h:mm A')}</Typography>&nbsp;
                        <Typography className={commonStyles.InputLabel}>{findDuration(eventDatas?.duration_from_time, eventDatas?.duration_to_time)}</Typography>
                    </Box>
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>Location</Typography>

                    <Grid container
                        direction="row"
                        justifyContent="flex-start"

                    >
                        <Grid item sm={5} xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: { xs: "100%", sm: '70%', md: "70%", xl: '70%' } }}>
                                    <Typography variant="span" className={commonStyles.InputLabel}  >
                                        <MapPinIcon style={{ height: 20, width: 20 }} /> &nbsp;
                                        {eventDatas?.address}
                                    </Typography>
                                </Box>
                                <Button variant="contained" sx={{ borderRadius: '50px' }}
                                    onClick={() => openGoogleMaps(eventDatas?.latitude, eventDatas?.longitude)}
                                >Open in map</Button>
                            </Box>

                            <Box mt={1}>
                                <Box sx={{ height: 300, width: "100%" }}>
                                    <PreviewMap coordinates={{ lat: eventDatas?.latitude, lng: eventDatas?.longitude }} />
                                </Box>
                            </Box>

                        </Grid>

                        <Grid item ></Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>Rotarian</Typography>
                    <Typography sx={{ fontSize: '14px' }}> {eventDatas?.rotarian?.rotarian_id ?? "-"}</Typography>
                    <Typography sx={{ fontSize: '12px' }}>{eventDatas?.rotarian?.rotarian_district ?? "-"}</Typography>
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>About the event</Typography>
                    {eventDatas?.description ? <div dangerouslySetInnerHTML={{ __html: isJSONString(eventDatas?.description) ? JSON.parse(eventDatas?.description) : eventDatas?.description }} /> : null}
                    {/* <ul>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Greetings, Rotarians!
                                *“Build your own dreams or someone else will hire you to build theirs.” – Farrah Gray*
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                To mentor students in cultivating the skills and expertise required to discern career opportunities and explore avenues for attaining their professional aspirations *Rotary Club of Virudhunagar and Idhayam in association with JCI Theni Honeybee* hosted the event *'Project Kalam - 69.0'*, a Career Guidance Program from 10:00 AM till 12:00 PM at Theni K S Public School, Koduvilarpatti on June 28, 2024 (Friday).
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                *Rtn. P.Tenzing M.B.A., (Founder, Step2Success)* addressed the students with his enthralling career guidance session.
                                *Thiru. D.Rajesh (Joint Secretary, Theni K S Public School)* honored the event with his presence as the Chief Guest; while Dr. J.Bala Prema Devi (Principal, Theni K S Public School) adorned the event with her presence.
                                We would like to share the feedback of the students who attended this event.
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                This program was an eye opener and created awareness among our students.
                                We express our gratitude to *Jc. K.Revan Sidharth, President, JCI Theni Honeybee; Jc. Ariyakumar, Secretary, JCI Theni Honeybee; Rtn. MPHF V.V.S.K.Shanmugam, President, Rotary Club of Virudhunagar; Rtn. MPHF V.N.M.A.K.Rajivan, Secretary, Rotary Club of Virudhunagar and Rtn. K.C.Gurusamy, Project Coordinator - Project Kalam* for their invaluable support in orchestrating this event.
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Regards,
                                *District Communication Team, Rotary International District 3212.*
                                (On behalf of Rtn. AKS V.R.Muthu,
                                Past District Governor,
                                Rotary International District 3212.)
                            </Typography>
                        </li>
                    </ul> */}
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>Amenities</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 700, fontSize: "14px" }} >Food -</Typography> &nbsp;
                        <Stack
                            direction={"row"}
                            gap={"5px"}
                            flexWrap={"wrap"}
                        >
                            {eventDatas?.amenities &&
                                Array.isArray(eventDatas?.amenities) &&
                                eventDatas?.amenities?.map((item) => {
                                    return <Chip label={item?.amenities_type?.name} />
                                })
                            }
                        </Stack>

                    </Box>
                </Box>


                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>Terms & Conditions</Typography>
                    {eventDatas?.terms_condition?.description ?
                        <div dangerouslySetInnerHTML={{ __html: isJSONString(eventDatas?.terms_condition?.description) ? JSON.parse(eventDatas?.terms_condition?.description) : eventDatas?.terms_condition?.description }} />
                        :
                        null}
                    {/* <ul>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Entry is allowed only for valid ticket holders.
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Terms of Payment: Fees shall be agreed as stated in the relevant quotation. 50% of the Fees is required as deposit for the reservation of the Event Manager’s dates and prior to commencement of work (“Deposit”) and 50% of the Fees is due on the Event Date. Payment may be made by cash or cheque in favour of Events Wizard Sdn Bhd (Account No: 352093025101 HSBC). A charge of 10% of the total billing will be imposed on cheques returned for any reason. Event Manager reserves the right to charge interest at the rate of 2% per month.
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Cancellation: Full payment of the Fees is required to be settled if the Event is cancelled within 7 working days before the Event. If a substantial part of the Event thereof does not take place or is cancelled and no substitute event is provided to the Event Manager by then, the deposit of 50% of the total billing will be forfeited.
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Additional Fee: An additional fee will be imposed upon confirmation for last-minute venue/ceremony installation/preparation written requests. The Event Manager and your company shall negotiate in good faith with a view to agreeing upon a fair and reasonable fee.
                            </Typography>
                        </li>
                    </ul> */}

                </Box>


                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>FAQ’s</Typography>

                    <div style={{ marginTop: '10px' }}>
                        {/* {eventDatas?.faq && Array.isArray(eventDatas?.faq) &&
                            eventDatas?.faq?.map((item, index) => {
                                return <Accordion defaultExpanded={index === 0 ? true : false} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index + 1}-content`}
                                        id={`panel${index + 1}-header`}
                                    >
                                        <Typography>{item?.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Typography>
                                            {item?.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            })
                        } */}
                        <CustomizedAccordions
                            items={eventDatas?.faq && Array.isArray(eventDatas?.faq) ? eventDatas?.faq : []}
                            summary={"question"}
                            details={"answer"}
                        />

                    </div>

                </Box>

            </Box>

        </Box>
    </Container >
}