import { Fragment } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
import Header from './Components/Header';
import NotFound from './Components/PageNotFound';
import EventPreview from './Pages/EventPreview';
import SnackBarPopUp from './Components/common/SnackBarPopUp';
import BuyTicket from './Pages/EventPreview/BuyTicket';
import StatusPage from './StatusPage';

function App() {
  return (
    <Fragment>
      <SnackBarPopUp />
      <BrowserRouter >
        <Routes>
          <Route path='/:id' element={<EventPreview />} />
          <Route path='event_preview/:id' element={<EventPreview />} />
          <Route path='buy_ticket/:id' element={<BuyTicket />} />
          <Route path='/buy_ticket/status' element={<StatusPage />} />
          <Route path="/*" element={<Header><NotFound /></Header>} />
        </Routes>
      </BrowserRouter>
    </Fragment>)
}

export default App;
