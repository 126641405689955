import { makeStyles } from '@mui/styles';


const useCommonStyles = makeStyles(theme => ({
    InputLabel: {
        "&.MuiTypography-root": {
            fontSize: "14px",
            color: '#636363'
        },
    },
    customInput: {
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "8.5px 10px",
            fontSize: '12px',
        },
        backgroundColor: '#FFF',
        "& .MuiFormHelperText-root": {
            margin: 0
        }
    },
    FaqInput: {
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
            // padding: "8.5px 10px",
            fontSize: '12px'
        },
        backgroundColor: '#FFF'
    },
    customPriceInput: {
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            fontSize: '12px',
            paddingLeft: '0px',
            "& .MuiInputAdornment-root": {
                padding: '17px 7px',
                backgroundColor: '#EEEEEE',
                borderRight: '1px solid #828282B2',
                height: '100%',
                "& .MuiTypography-root": {
                    fontSize: "11px",
                    fontWeight: 700,

                }
            }
        },
        "& input": {
            padding: "8.5px 10px",
        },
        backgroundColor: '#FFF'
    },
    commonFormTitle: {
        "&.MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 700
        }
    },
    dropDownLabel: {
        fontSize: "12px!important",
    },
    datePicker: {
        '& .MuiOutlinedInput-root': {
            paddingRight: '0px',
            backgroundColor: '#FFFFFF',
            height: '30px'
        },
        '& .MuiInputBase-input': {
            padding: "5px 5px 5px", fontSize: "0.675rem",
            borderRadius: '6px',
            // textAlign: 'center',
            color: '#405D6C'
        },
        // "& fieldset": { border: "none" },
        "& .MuiButtonBase-root": {
            marginRight: '4px',
            padding: '4px'
        },
        "& .MuiInputAdornment-root": {
            marginLeft: '0px'
        },
        "&.MuiFormControl-root.MuiTextField-root": {
            minWidth: "85px",
        }
    },
    customBox: {
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: "1px solid #A9A9A980",
        width: '100%',
        borderRadius: "10px",
        "&:before": {
            content: '""',
            backgroundColor: "#611180",
            width: "100%",
            position: "absolute",
            height: "5px",
            borderRadius: "10px 10px 0px 0px"
        }
    },

    amountInput: {
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            fontSize: '12px',
            paddingLeft: '0px',
            "& .MuiInputAdornment-root": {
                padding: '17px 7px',
                backgroundColor: '#EEEEEE',
                borderRight: '1px solid #828282B2',
                height: '100%',
                "& .MuiTypography-root": {
                    fontSize: "11px",
                    fontWeight: 700,

                }
            }
        },
        "& input": {
            padding: "6.5px 10px",
        },
        "& fieldset": {
            border: 'none'
        }
    },
    commonAutoComplete: {
        "& .MuiAutocomplete-input": { padding: '1px!important', fontSize: '12px' },
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": { padding: '7.5px 8px 7.5px 5px' },
        "& .MuiSvgIcon-root": { fontSize: "12px" },
        "&.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root": { px: '6px' }
    }

}))

export default useCommonStyles