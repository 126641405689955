import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

const initialState = {
    getEventLoading: false,
    getEvent: null,
    getClubNamesLoading: false,
    getClubNames: null,
    getDesignationLoading: false,
    getDesignation: null,
    getFoodTypesLoading: false,
    getFoodTypes: null,
    getShirtTypeLoading: false,
    getShirtType: null,
    createBookingLoading: false,
    createBooking: null,

}

export const getEvent = createAsyncThunk('getEvent', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/eventsDetailsBySlug?slug=${data}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const getClubNames = createAsyncThunk('getClubNames', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_club_name`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getDesignation = createAsyncThunk('getDesignation', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_designation`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const getFoodTypes = createAsyncThunk('getFoodTypes', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/food_type`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const getShirtType = createAsyncThunk('getShirtType', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_shirt_type`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const createBooking = createAsyncThunk('createBooking', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/${data.id}/create_booking`, data?.payload);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


const BookEvent = createSlice({
    name: 'BookEvent',
    initialState,
    reducers: {
        setGetEventDataToNull: (state) => {
            state.getEvent = null
        },
        setGetClubNamesToNull: (state) => {
            state.getClubNames = null
        },
        setGetDesignationToNull: (state) => {
            state.getDesignation = null
        },
        setGetFoodTypesToNull: (state) => {
            state.getFoodTypes = null
        },
        setGetShirtTypeToNull: (state) => {
            state.getShirtType = null
        },
        setCreateBookingToNull: (state) => {
            state.createBooking = null
        },

    },
    extraReducers: (builders) => {
        //--> GetEventData
        builders.addCase(getEvent.fulfilled, (state, action) => {
            state.getEvent = action.payload.data
            state.getEventLoading = false
        })
        builders.addCase(getEvent.pending, (state, action) => {
            state.getEventLoading = true
        })
        builders.addCase(getEvent.rejected, (state, action) => {
            state.getEvent = action?.payload?.response?.data
            state.getEventLoading = false
        })

        //--> getClubNames
        builders.addCase(getClubNames.fulfilled, (state, action) => {
            state.getClubNames = action.payload.data
            state.getClubNamesLoading = false
        })
        builders.addCase(getClubNames.pending, (state, action) => {
            state.getClubNamesLoading = true
        })
        builders.addCase(getClubNames.rejected, (state, action) => {
            state.getClubNames = action?.payload?.response?.data
            state.getClubNamesLoading = false
        })


        //--> getDesignation
        builders.addCase(getDesignation.fulfilled, (state, action) => {
            state.getDesignation = action.payload.data
            state.getDesignationLoading = false
        })
        builders.addCase(getDesignation.pending, (state, action) => {
            state.getDesignationLoading = true
        })
        builders.addCase(getDesignation.rejected, (state, action) => {
            state.getDesignation = action?.payload?.response?.data
            state.getDesignationLoading = false
        })

        //--> getFoodTypes
        builders.addCase(getFoodTypes.fulfilled, (state, action) => {
            state.getFoodTypes = action.payload.data
            state.getFoodTypesLoading = false
        })
        builders.addCase(getFoodTypes.pending, (state, action) => {
            state.getFoodTypesLoading = true
        })
        builders.addCase(getFoodTypes.rejected, (state, action) => {
            state.getFoodTypes = action?.payload?.response?.data
            state.getFoodTypesLoading = false
        })

        //--> getShirtType
        builders.addCase(getShirtType.fulfilled, (state, action) => {
            state.getShirtType = action.payload.data
            state.getShirtTypeLoading = false
        })
        builders.addCase(getShirtType.pending, (state, action) => {
            state.getShirtTypeLoading = true
        })
        builders.addCase(getShirtType.rejected, (state, action) => {
            state.getShirtType = action?.payload?.response?.data
            state.getShirtTypeLoading = false
        })

        //--> createBooking
        builders.addCase(createBooking.fulfilled, (state, action) => {
            state.createBooking = action.payload.data
            state.createBookingLoading = false
        })
        builders.addCase(createBooking.pending, (state, action) => {
            state.createBookingLoading = true
        })
        builders.addCase(createBooking.rejected, (state, action) => {
            state.createBooking = action?.payload?.response?.data
            state.createBookingLoading = false
        })


    }
})

export const { setGetEventDataToNull, setGetClubNamesToNull, setGetDesignationToNull, setGetFoodTypesToNull, setGetShirtTypeToNull, setCreateBookingToNull } = BookEvent.actions
export default BookEvent.reducer