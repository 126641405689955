import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import SideMenuBar from '../SideMenuBar';
import LogoImage from "../../assets/Login/LogoImage.png";
import { IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

export default function Header({ children, openBar = true }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate()


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ backgroundColor: "#232A33", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={LogoImage} style={{ height: 34, width: 70 }} />
                    </Box>

                </Toolbar>
            </AppBar>
            {/* <Drawer
                variant="persistent"
                anchor="left"
                open={openBar}
                sx={{
                    width: openBar ? drawerWidth : 0,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: openBar ? drawerWidth : 0, boxSizing: 'border-box' },
                    backgroundColor: '#FFFFFF',

                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>


                    <SideMenuBar />
                </Box>
            </Drawer> */}



            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {/* <Toolbar /> */}
                {children}
            </Box>


        </Box>
    );
}